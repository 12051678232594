@import 'colors';
@import 'variables';

@mixin breakpoint($platform: 'desktop') {
    @if $platform == 'desktop' {
        @media only screen and (min-width: $desktop-min-size) and (max-width: $desktop-max-size) {
            @content;
        }
    }

    @if $platform == 'tablet' {
        @media only screen and (min-width: $tablet-min-size) and (max-width: $tablet-max-size) {
            @content;
        }
    }

    @if $platform == '>phone' {
        @media only screen and (min-width: $phone-min-size) {
            @content;
        }
    }

    @if $platform == 'phone' {
        @media only screen and (min-width: $phone-min-size) and (max-width: $phone-max-size) {
            @content;
        }
    }
}

@mixin transition($duration: 0.15s, $property: all, $easing: ease-in-out) {
    transition: $duration $property $easing;
}

@mixin is-in-content-layer() {
    margin-top: $content-layer-spacing;
}

@mixin h1-schl-guide {
    font-size: 16px;
    font-weight: bold;
    color: $carbon-gray;
}
