@import '../global/colors';
@import '../global/mixins';

.bx__image-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 26rem);
    grid-auto-rows: 15rem;
    grid-column-gap: 5rem;
    grid-row-gap: 2.5rem;
}

.bx__card {
    background: $white;

    border: 1px solid $gray;
    border-radius: 5px;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    padding: 3rem;

    @include transition(0.15s, box-shadow);

    &:hover {
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
    }

    &.image {
        padding: 0;

        figure {
            height: 15rem;

            border-radius: 10px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;

            img {
                height: 10rem;

                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            figcaption {
                background-color: $white;

                min-height: 5rem;
                width: 100%;

                font-size: 1.625rem;
                font-weight: 500;
                color: $dark-gray;
                line-height: 1.9;
                text-align: center;

                border-top: 1px solid $gray;
                margin-top: auto;
                padding: 1rem;
                position: absolute;
                top: 10rem;

                @include transition(0.15s, transform);
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1025px) and (orientation: portrait) {
    .bx__image-card-grid {
        grid-column-gap: 3rem;
    }
}
