.flex {
    display: flex;
}

.full {
    width: 100%;
    flex: 0 0 100%;
}

.half {
    width: 25%;
    flex: 0 0 50%;
}

.quarter {
    width: 25%;

    flex: 0 0 50%;
}

.flex-row {
    flex-flow: row wrap;
}
