@import 'colors';
@import 'variables';
@import 'mixins';

.content-group {
    padding: 2rem 4rem;

    > header {
        min-width: 20rem;

        border-bottom: 2px solid $brand-green;
        display: inline-block;
        padding-right: 2rem;

        h1 {
            font-size: 2.5rem;
            font-weight: 500;
            color: $dark-gray;

            padding: 0.5rem 0;
        }
    }

    .sub-group {
        > header {
            h1 {
                @include h1-schl-guide;

                margin: 5rem 0 2.5rem 0;
            }
        }
    }
}

.bx__content-container {
    margin-top: $app-bar-height;

    display: flex;
    align-items: center;
    justify-content: center;

    router-outlet + *:not(bx-editor-container):not(bx-model-editor) {
        margin-bottom: 2rem;
    }
}

router-outlet + * {
    // clearfix for collapsing margins
    display: block;
    flex: 1;
}
