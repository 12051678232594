@import 'colors';
@import 'mixins';

html {
    font-size: 8px;
}

body {
    font-family: $font-stack;
    font-size: 1.625rem;
    color: $dark-gray;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

h1 {
    font-size: 3rem;
    line-height: 4rem;

    margin-bottom: .5rem;
}

h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
}

h3 {
    font-size: 2rem;
    line-height: 3rem;
}

h4 {
    font-size: 1.625rem;
    line-height: 2.75rem;
}

p {
    font-size: 1.625rem;
    line-height: 2.75rem;

    margin-bottom: 5rem;
}

button {
    font-size: 1.5rem;
    line-height: 2.2rem;
}

@include breakpoint('mobile') {
    html {
        font-size: 8px;
    }
}

button {
    font-size: 1.625rem;
    line-height: 3rem;
}
